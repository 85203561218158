import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "./components/Dashboard.vue";
import store from "./store";

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/formlayout",
    name: "formlayout",
    component: () => import("./components/FormLayoutDemo.vue"),
  },
  {
    path: "/input",
    name: "input",
    component: () => import("./components/InputDemo.vue"),
  },
  {
    path: "/floatlabel",
    name: "floatlabel",
    component: () => import("./components/FloatLabelDemo.vue"),
  },
  {
    path: "/invalidstate",
    name: "invalidstate",
    component: () => import("./components/InvalidStateDemo.vue"),
  },
  {
    path: "/button",
    name: "button",
    component: () => import("./components/ButtonDemo.vue"),
  },
  {
    path: "/table",
    name: "table",
    component: () => import("./components/TableDemo.vue"),
  },
  {
    path: "/list",
    name: "list",
    component: () => import("./components/ListDemo.vue"),
  },
  {
    path: "/tree",
    name: "tree",
    component: () => import("./components/TreeDemo.vue"),
  },
  {
    path: "/panel",
    name: "panel",
    component: () => import("./components/PanelsDemo.vue"),
  },
  {
    path: "/overlay",
    name: "overlay",
    component: () => import("./components/OverlayDemo.vue"),
  },
  {
    path: "/menu",
    component: () => import("./components/MenuDemo.vue"),
    children: [
      {
        path: "",
        component: () => import("./components/menu/PersonalDemo.vue"),
      },
      {
        path: "/menu/seat",
        component: () => import("./components/menu/SeatDemo.vue"),
      },
      {
        path: "/menu/payment",
        component: () => import("./components/menu/PaymentDemo.vue"),
      },
      {
        path: "/menu/confirmation",
        component: () => import("./components/menu/ConfirmationDemo.vue"),
      },
    ],
  },
  {
    path: "/messages",
    name: "messages",
    component: () => import("./components/MessagesDemo.vue"),
  },
  {
    path: "/file",
    name: "file",
    component: () => import("./components/FileDemo.vue"),
  },
  {
    path: "/chart",
    name: "chart",
    component: () => import("./components/ChartDemo.vue"),
  },
  {
    path: "/misc",
    name: "misc",
    component: () => import("./components/MiscDemo.vue"),
  },
  {
    path: "/crud",
    name: "crud",
    component: () => import("./pages/CrudDemo.vue"),
  },
  {
    path: "/survey",
    component: () => import("./pages/Survey.vue"),
    children: [
      {
        path: "",
        name: "survey",
        components: { survey: () => import("./components/survey/Survey.vue") },
      },
      {
        path: ":surveyid",
        components: {
          survey: () => import("./components/survey/Survey.vue"),
          question: () => import("./components/survey/Question.vue"),
        },
      },
    ],
  },
  {
    path: "/response",
    component: () => import("./pages/Response.vue"),
    children: [
      {
        path: "",
        name: "response",
        components: {
          response: () => import("./components/response/Response.vue"),
        },
      },
      {
        path: ":responseid",
        name: "responseform",
        components: {
          responseform: () => import("./components/response/ResponseForm.vue"),
        },
      },
    ],
  },
  {
    path: "/surveyform",
    component: () => import("./pages/SurveyForm.vue"),
    children: [
      {
        path: ":surveyid",
        name: "surveyform",
        components: {
          surveyform: () => import("./components/survey/SurveyForm.vue"),
        },
      },
    ],
  },
  {
    path: "/customsurvey",
    component: () => import("./pages/SurveyForm.vue"),
    children: [
      {
        path: ":surveyid",
        name: "surveyformcustom",
        components: {
          surveyform: () => import("./components/survey/SurveyFormCustom.vue"),
        },
      },
    ],
  },
  // {
  //   path: "/surveyform",
  //   name: "surveyform",
  //   component: () => import("./pages/SurveyForm.vue"),
  // },
  // {
  //   path: "/survey",
  //   name: "survey",
  //   component: () => import("./pages/Survey.vue"),
  // },
  // {
  //   path: "/question",
  //   name: "question",
  //   component: () => import("./pages/Question.vue"),
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/timeline",
    name: "timeline",
    component: () => import("./pages/TimelineDemo.vue"),
  },
  {
    path: "/empty",
    name: "empty",
    component: () => import("./components/EmptyPage.vue"),
  },
  {
    path: "/documentation",
    name: "documentation",
    component: () => import("./components/Documentation.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !store.getters?.isUser && to.name !== "surveyform" && to.name !== "responseform") next({ name: "login" });
  else next();
});

export default router;
