<template>
  <div class="layout-footer">
    <img alt="Logo" src="/images/heron-logo.png" height="20" class="mr-2" />
    <span class="font-medium ml-2">HERON</span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    darkTheme() {
      return this.$appState.theme.startsWith("saga");
    },
  },
};
</script>

<style scoped></style>
