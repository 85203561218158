<template>
  <div id="layout-config" :class="containerClass">
    <a id="layout-config-button" href="#" class="layout-config-button" @click="toggleConfigurator">
      <i class="pi pi-cog" />
    </a>
    <Button class="p-button-danger layout-config-close p-button-rounded p-button-text" icon="pi pi-times" @click="hideConfigurator" />

    <div class="layout-config-content">
      <h6 class="mt-0">Component Scale</h6>
      <div class="config-scale">
        <Button icon="pi pi-minus" class="p-button-text" :disabled="scale === scales[0]" @click="decrementScale()" />
        <i v-for="s of scales" :key="s" class="pi pi-circle-on" :class="{ 'scale-active': s === scale }" />
        <Button icon="pi pi-plus" class="p-button-text" :disabled="scale === scales[scales.length - 1]" @click="incrementScale()" />
      </div>

      <h6>Input Style</h6>
      <div class="p-formgroup-inline">
        <div class="field-radiobutton">
          <RadioButton
            id="input_outlined"
            name="inputstyle"
            value="outlined"
            :model-value="$primevue.config.inputStyle"
            @change="changeInputStyle('outlined')"
          />
          <label for="input_outlined">Outlined</label>
        </div>
        <div class="field-radiobutton">
          <RadioButton id="input_filled" name="inputstyle" value="filled" :model-value="$primevue.config.inputStyle" @change="changeInputStyle('filled')" />
          <label for="input_filled">Filled</label>
        </div>
      </div>

      <h6>Ripple Effect</h6>
      <InputSwitch :model-value="rippleActive" @update:modelValue="changeRipple" />

      <h6>Menu Type</h6>
      <div class="p-formgroup-inline">
        <div class="field-radiobutton">
          <RadioButton id="static" v-model="d_layoutMode" name="layoutMode" value="static" @change="changeLayout($event, 'static')" />
          <label for="static">Static</label>
        </div>
        <div class="field-radiobutton">
          <RadioButton id="overlay" v-model="d_layoutMode" name="layoutMode" value="overlay" @change="changeLayout($event, 'overlay')" />
          <label for="overlay">Overlay</label>
        </div>
      </div>

      <h6>Color Scheme</h6>
      <div class="p-formgroup-inline">
        <div class="field-radiobutton">
          <RadioButton id="light" v-model="themeScheme" name="layoutColorMode" value="saga" @change="changeThemeScheme('saga')" />
          <label for="light">Light</label>
        </div>
        <div class="field-radiobutton">
          <RadioButton id="dim" v-model="themeScheme" name="layoutColorMode" value="vela" @change="changeThemeScheme('vela')" />
          <label for="dim">Dim</label>
        </div>
        <div class="field-radiobutton">
          <RadioButton id="dark" v-model="themeScheme" name="layoutColorMode" value="arya" @change="changeThemeScheme('arya')" />
          <label for="dark">Dark</label>
        </div>
      </div>

      <h6>Primary Color</h6>
      <div class="flex">
        <div style="width: 2rem; height: 2rem; border-radius: 6px" class="bg-blue-500 mr-3 cursor-pointer" @click="changeThemeColor('blue')" />
        <div style="width: 2rem; height: 2rem; border-radius: 6px" class="bg-green-500 mr-3 cursor-pointer" @click="changeThemeColor('green')" />
        <div style="width: 2rem; height: 2rem; border-radius: 6px" class="bg-orange-500 mr-3 cursor-pointer" @click="changeThemeColor('orange')" />
        <div style="width: 2rem; height: 2rem; border-radius: 6px" class="bg-purple-500 cursor-pointer" @click="changeThemeColor('purple')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layoutMode: {
      type: String,
      default: null,
    },
    layoutColorMode: {
      type: String,
      default: null,
    },
  },
  emits: ["layout-color-change", "layout-change"],
  data() {
    return {
      active: false,
      d_layoutMode: this.layoutMode,
      d_layoutColorMode: this.layoutColorMode,
      scale: 16,
      scales: [12, 13, 14, 15, 16],
      themeScheme: "saga",
      themeColor: "blue",
    };
  },
  computed: {
    containerClass() {
      return ["layout-config", { "layout-config-active": this.active }];
    },
    rippleActive() {
      return this.$primevue.config.ripple;
    },
    inputStyle() {
      return this.$appState.inputStyle;
    },
  },
  watch: {
    $route() {
      if (this.active) {
        this.active = false;
        this.unbindOutsideClickListener();
      }
    },
    layoutMode(newValue) {
      this.d_layoutMode = newValue;
    },
    layoutColorMode(newValue) {
      this.d_layoutColorMode = newValue;
    },
  },
  outsideClickListener: null,
  methods: {
    toggleConfigurator(event) {
      this.active = !this.active;
      event.preventDefault();

      if (this.active) this.bindOutsideClickListener();
      else this.unbindOutsideClickListener();
    },
    hideConfigurator(event) {
      this.active = false;
      this.unbindOutsideClickListener();
      event.preventDefault();
    },
    changeInputStyle(value) {
      this.$primevue.config.inputStyle = value;
    },
    changeRipple(value) {
      this.$primevue.config.ripple = value;
    },
    changeLayout(event, layoutMode) {
      this.$emit("layout-change", layoutMode);
      event.preventDefault();
    },
    changeLayoutColor(event, layoutColor) {
      this.$emit("layout-color-change", layoutColor);
      event.preventDefault();
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.active && this.isOutsideClicked(event)) {
            this.active = false;
          }
        };
        document.addEventListener("click", this.outsideClickListener);
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener("click", this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    isOutsideClicked(event) {
      return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
    },
    decrementScale() {
      this.scale--;
      this.applyScale();
    },
    incrementScale() {
      this.scale++;
      this.applyScale();
    },
    applyScale() {
      document.documentElement.style.fontSize = this.scale + "px";
    },
    changeTheme() {
      let theme = this.themeScheme + "-" + this.themeColor;
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute("href", themeElement.getAttribute("href").replace(this.$appState.theme, theme));
      this.$appState.theme = theme;
    },
    changeThemeScheme(scheme) {
      this.themeScheme = scheme;
      this.changeTheme();
    },
    changeThemeColor(color) {
      this.themeColor = color;
      this.changeTheme();
    },
  },
};
</script>
