import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      jwt: null,
      user: {},
    };
  },
  mutations: {
    login: (state, { jwt, user }) => {
      state.jwt = jwt;
      state.user = user;
    },
    logout: (state) => {
      state.jwt = null;
      state.user = null;
      delete state.jwt;
      delete state.user;
    },
  },

  getters: {
    jwt: (state) => {
      return state.jwt;
    },
    user: (state) => {
      return state.user;
    },
    userId: (state) => {
      return state.user?.id ? state.user.id : -1;
    },
    userType: (state) => {
      return state.user?.role?.type ? state.user.role.type : null;
    },
    isUser: (state) => {
      return null != state.jwt && null != state.user?.id;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("login", payload);
    },
    logout({ commit }, payload) {
      commit("logout", payload);
    },
  },
});

export default store;
